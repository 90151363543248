/* titillium-web-200 - latin */

@font-face {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/titillium-web-v9-latin-200.woff2") format("woff2"),
    url("../fonts/titillium-web-v9-latin-200.woff") format("woff");
}

/* titillium-web-200italic - latin */

@font-face {
  font-family: "Titillium Web";
  font-style: italic;
  font-weight: 200;
  src: url("../fonts/titillium-web-v9-latin-200italic.woff2") format("woff2"),
    url("../fonts/titillium-web-v9-latin-200italic.woff") format("woff");
}

/* titillium-web-300 - latin */

@font-face {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/titillium-web-v9-latin-300.woff2") format("woff2"),
    url("../fonts/titillium-web-v9-latin-300.woff") format("woff");
}

/* titillium-web-300italic - latin */

@font-face {
  font-family: "Titillium Web";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/titillium-web-v9-latin-300italic.woff2") format("woff2"),
    url("../fonts/titillium-web-v9-latin-300italic.woff") format("woff");
}

/* titillium-web-regular - latin */

@font-face {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/titillium-web-v9-latin-regular.woff2") format("woff2"),
    url("../fonts/titillium-web-v9-latin-regular.woff") format("woff");
}

/* titillium-web-600 - latin */

@font-face {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/titillium-web-v9-latin-600.woff2") format("woff2"),
    url("../fonts/titillium-web-v9-latin-600.woff") format("woff");
}

/* titillium-web-600italic - latin */

@font-face {
  font-family: "Titillium Web";
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/titillium-web-v9-latin-600italic.woff2") format("woff2"),
    url("../fonts/titillium-web-v9-latin-600italic.woff") format("woff");
}

/* titillium-web-italic - latin */

@font-face {
  font-family: "Titillium Web";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/titillium-web-v9-latin-italic.woff2") format("woff2"),
    url("../fonts/titillium-web-v9-latin-italic.woff") format("woff");
}

/* titillium-web-700 - latin */

@font-face {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/titillium-web-v9-latin-700.woff2") format("woff2"),
    url("../fonts/titillium-web-v9-latin-700.woff") format("woff");
}

/* titillium-web-700italic - latin */

@font-face {
  font-family: "Titillium Web";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/titillium-web-v9-latin-700italic.woff2") format("woff2"),
    url("../fonts/titillium-web-v9-latin-700italic.woff") format("woff");
}

/* titillium-web-900 - latin */

@font-face {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/titillium-web-v9-latin-900.woff2") format("woff2"),
    url("../fonts/titillium-web-v9-latin-900.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-weight: 100;
  font-style: normal;
  src: url('../fonts/Inter-Thin.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/Inter-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/Inter-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  font-style: italic;
  src: url('../fonts/Inter-Italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  font-style: normal;
  src: url('../fonts/Inter-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/Inter-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 900;
  font-style: normal;
  src: url('../fonts/Inter-Black.woff2') format('woff2');
}




