.table-num {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: white;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    line-height: 26px;
    display: inline-block;
    padding: 2px 2px 2px 2px;
    background-color: #2B7ABE;
    border: 2px solid #2B7ABE;
}

.table-num.classic {
    background-color: #FF8200;
    border: 2px solid #FF8200;
}
