.md-sidebar {
  @include break-from-device(screen) {
    top: px2rem(80px);

    .md-nav--lifted>.md-nav__list>.md-nav__item--active>.md-nav__link {
      display: none;
    }
  }
  &--primary {
    left: auto !important;

    @include dropdown;
    .book-selector {
      height: px2rem(56px);
      visibility: hidden;
    }
    .choices {
      margin-left: 12px;
    }
    .md-nav__link {
      font-weight: 600;
    }
    @include break-from-device(screen) {
      .md-nav__item--nested {
        nav {
          .md-nav {
            border-left: 1px solid var(--md-primary-fg-color);
            margin-left: px2rem(6px);
          }
        }
      }
    }
    .md-nav__item {
      padding-left: px2rem(14px);
      margin-top: px2rem(4px);
      &:not(.md-nav__item--nested) .md-nav__link {
        font-weight: normal;
      }
    }
  }
  &--secondary {
    .nav-wrapper {
      .md-nav__title {
        @include caption;
        padding-left: px2rem(6px);
        font-weight: 700;
      }
      .md-nav__item {
        padding-left: px2rem(12px);
      }
      .md-nav__link {
        @include caption;
        margin-top: 0;
      }
    }
  }

  @include break-to-device(tablet) {
    &--primary {
      width: 300px;
      left: auto;
      right: 0;
      transform: translateX(100%);
      [data-md-toggle="drawer"]:checked ~ .md-container & {
        transform: translateX(0);
      }
    }

    .md-nav__title[for="__drawer"] {
      padding: px2rem(8px) px2rem(32px);
      color: var(--md-typeset-color);
      background-color: transparent;
      height: 50px;
      .md-logo {
        display: none;
      }
    }

    .md-nav--primary .md-nav__item--active>.md-nav__link {
      color: var(--md-primary-a);
    }

    .md-nav--primary .choices {
      margin-left: px2rem(32px);
      max-width: 260px;
      .choices__inner {
        width: 255px;
      }
    }
  }
}

// Implements mobile UI fixes requested in https://datarobot.atlassian.net/browse/DOC-7714

.md-sidebar__scrollwrap {
  overflow-y: scroll !important;
  /* To override default style. Inline CSS didn't work. */
}

@media screen and (max-width: 960px) {

  .md-nav__link--active:nth-of-type(2),
  .md-nav__link--active:nth-of-type(3) {
    display: none;
  }

  .md-nav--secondary>.notification,
  .md-nav--secondary>.feedback,
  .md-nav--secondary>.feedback-success {
    display: none;
  }

}

