.feedback {
  width: 177px;
  margin: 0 0 px2rem(40px) px2rem(6px);
  .feedback-title {
    margin: 0 0 px2rem(12px) 0;
    @include subtitle-bold;
  }
  .feedback-button-container {
    display: flex;
    margin-left: px2rem(12px);
    button {
      @include feedback-button;
    }
  }
}

.feedback-success {
  @include h4;
  margin: 0 0 px2rem(32px) px2rem(15px);
  color: var(--md-typeset-color);
  display: none;
}

.feedback-body {
  outline: none;
  border-color: var(--md-selectbox-color);
  border-radius: 6px;
  padding: px2rem(8px) px2rem(16px);
  @include paragraph-regular;
  color: var(--md-selectbox-color);
  font-weight: 400;
  &::placeholder {
    @include paragraph-regular;
    color: var(--md-selectbox-color);
    font-weight: 400;
  }
}

/* Mobile feedback form */
.md-content {
  .feedback {
    margin: px2rem(24px) auto 0;
  }
  .feedback-success {
    margin: px2rem(24px) auto 0;
    text-align: center;
  }
  .send-feedback {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 327px;
    height: 173px;
    margin: px2rem(24px) auto 0;
    .feedback-header {
      @include h4;
      margin: 0;
      color: var(--md-typeset-color);
    }
    .feedback-body {
      height: 64px;
    }
    .feedback-submit {
      display: inline-block;
      padding: px2rem(8px) px2rem(14px);
      margin: 0 auto;
      @include orange_active_button;
      @include action-item-bold;
    }
  }
}
/* End mobile feedback form */

/* Desktop feedback form (modal) */
@include break-from-device(tablet landscape) {
  .md-content {
    .feedback, .send-feedback, .feedback-success {
      display: none !important;
    }
  }
}

.feedback-modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,.6);

  .modal-content {
    display: flex;
    align-items: center;
    height: 100%;
    .dr-modal-body {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: space-between;
      width: 608px;
      height: 342px;
      margin: 0 auto;
      padding: px2rem(32px);
      border-radius: 6px;
      background-color: var(--md-primary-bg-color);
      .close-button {
        position: absolute;
        right: px2rem(18px);
        top: px2rem(18px);
        cursor: pointer;
      }
      .feedback-header {
        @include h3;
        margin: 0;
        color: var(--md-typeset-color);
      }
      .feedback-body {
        height: 150px;
      }
      .feedback-submit {
        text-align: center;
        padding: px2rem(13px) 0;
        @include orange_active_button;
        @include action-item-bold;
      }
    }
  }
}
/* End desktop feedback form */
