.algolia-search {
  .ais-SearchBox-form {
    display: flex;
    position: relative;
    border-bottom: 1px solid var(--dr-border-color);

    .ais-SearchBox-input {
      width: 100%;
      outline: none;
      border-width: 0;
    }
  }

  input {
    padding: 8px 12px 8px 40px;
    font-size: 1.2rem;
    line-height: 20px;

    &::placeholder {
      color: var(--dr-text-muted);
    }

    &:focus {
      box-shadow: var(--dr-secondary-hover) 0 0 0 2px inset;

      &::placeholder {
        color: var(--dr-text-muted);
      }
    }
  }

  .ais-SearchBox-submit,
  .ais-SearchBox-reset {
    background: transparent;
    border: none;
    position: absolute;
  }
  button {
    svg {
      height: 14px;
      width: 14px;
      fill: var(--dr-text-muted);
    }
  }

  .ais-SearchBox-submit {
    top: 10px;
    left: 0;
    padding: 2px var(--ds-spacing-03) 0 var(--ds-spacing-03);
  }
  .ais-SearchBox-reset {
    top: 10px;
    right: 8px;
  }

  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  .hit {
    display: flex;
    flex-direction: column;
    margin: 1rem;

    .hit-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;

      .hit-icon {
        flex: 1 0 0;

        svg {
          height: 0.8rem;
          width: 0.8rem;
        }
      }

      .breadcrumbs {
        color: var(--md-text-gray-out);
        flex: 90% 1 0;
        font-size: 0.8rem;

        span {
          &.delim {
            font-size: 14px;
            padding: 0 0.5rem;
            margin: 0;
          }
        }
      }

      .hit-badge {
        flex: 1 0 0;
        text-align: right;
      }
    }

    .hit-item a {
      color: var(--dr-text-primary);
      text-decoration: underline;
      font-size: 1.2rem;
    }

    .hit-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
    }

    .hit-content {
      margin-top: 1rem;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .ais-Pagination {
    margin: 0 auto;
    padding: 1rem 0;
    max-width: 40%;

    .ais-Pagination-list {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      font-size: 16px;
      line-height: 24px;
      color: var(--md-typeset-muted-color);
    }

    .ais-Pagination-item--selected {
      a {
        color: var(--md-typeset-color);
        font-weight: bold;
        background: var(--md-primary-bg-color);
        box-shadow: 0 6px 8px rgba(54, 77, 114, 0.08),
          0 2px 4px rgba(54, 77, 114, 0.18);
        border-radius: 4px;
        padding: 8px 12px;
      }
    }

    .ais-Pagination-item--disabled {
      color: var(--md-header-button);
      opacity: 0.7;
    }

    .ais-Pagination-item--firstPage,
    .ais-Pagination-item--previousPage,
    .ais-Pagination-item--nextPage,
    .ais-Pagination-item--lastPage {
      font-size: 20px;
    }
  }
}
