// General admonition overrides

.admonition .admonition-title + p,
html .md-typeset details summary + p {
    margin-top: px2rem(12px);
}
html .md-typeset .admonition>:last-child,
html .md-typeset details>:last-child {
    margin-bottom: px2rem(12px);
}

// New admonition types

// Video 

:root {
  --md-admonition-icon--video: url('data:image/svg+xml;charset=utf-8,  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M0 3.75C0 2.784.784 2 1.75 2h12.5c.966 0 1.75.784 1.75 1.75v8.5A1.75 1.75 0 0 1 14.25 14H1.75A1.75 1.75 0 0 1 0 12.25Zm1.75-.25a.25.25 0 0 0-.25.25v8.5c0 .138.112.25.25.25h12.5a.25.25 0 0 0 .25-.25v-8.5a.25.25 0 0 0-.25-.25Z"/><path d="M6 10.559V5.442a.25.25 0 0 1 .379-.215l4.264 2.559a.25.25 0 0 1 0 .428l-4.264 2.559A.25.25 0 0 1 6 10.559Z"/></svg>')
}

.md-typeset .admonition.video,
.md-typeset details.video {
  border-color: rgb(158, 158, 158);
}

.md-typeset .video>.admonition-title,
.md-typeset .video>summary {
  background-color: rgba(158, 158, 158, 0.1);
}

.md-typeset .video>.admonition-title::before,
.md-typeset .video>summary::before {
  background-color: rgb(255, 0, 0);
  -webkit-mask-image: var(--md-admonition-icon--video);
  mask-image: var(--md-admonition-icon--video);
}

// Preview on 

:root {
  --md-admonition-icon--preview-on: url('data:image/svg+xml;charset=utf-8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10 13a2 2 0 0 1 2 2c0 1.11-.89 2-2 2s-2-.89-2-2 .9-2 2-2m8-12c-2.76 0-5 2.24-5 5v2H4c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2h-1V6c0-1.66 1.34-3 3-3s3 1.34 3 3v2h2V6c0-2.76-2.24-5-5-5m-2 9v10H4V10h12Z"/></svg>')
}

.md-typeset .admonition.preview-on,
.md-typeset details.preview-on {
  border-color: var(--md-typeset-muted-color);
}

.md-typeset .preview-on>.admonition-title,
.md-typeset .preview-on>summary {
  background-color: var(--md-admonition-title-bg);
}

.md-typeset .preview-on>.admonition-title::before,
.md-typeset .preview-on>summary::before {
  background-color: var(--md-typeset-muted-color);
  -webkit-mask-image: var(--md-admonition-icon--preview-on);
  mask-image: var(--md-admonition-icon--preview-on);
}

// Preview off

:root {
  --md-admonition-icon--preview-off: url('data:image/svg+xml;charset=utf-8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 17a2 2 0 0 1-2-2c0-1.11.89-2 2-2a2 2 0 0 1 2 2 2 2 0 0 1-2 2m6 3V10H6v10h12m0-12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V10c0-1.11.89-2 2-2h1V6a5 5 0 0 1 5-5 5 5 0 0 1 5 5v2h1m-6-5a3 3 0 0 0-3 3v2h6V6a3 3 0 0 0-3-3Z"/></svg>')
}

.md-typeset .admonition.preview-off,
.md-typeset details.preview-off {
  border-color: var(--md-typeset-muted-color);
}

.md-typeset .preview-off>.admonition-title,
.md-typeset .preview-off>summary {
  background-color: var(--md-admonition-title-bg);
}

.md-typeset .preview-off>.admonition-title::before,
.md-typeset .preview-off>summary::before {
  background-color: var(--md-typeset-muted-color);
  -webkit-mask-image: var(--md-admonition-icon--preview-off);
  mask-image: var(--md-admonition-icon--preview-off);
}

// Premium feature

:root {
  --md-admonition-icon--premium: url('data:image/svg+xml;charset=utf-8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.62L12 2 9.19 8.62 2 9.24l5.45 4.73L5.82 21 12 17.27Z"/></svg>')
}

.md-typeset .admonition.premium,
.md-typeset details.premium {
  border-color: var(--md-typeset-muted-color);
}

.md-typeset .premium>.admonition-title,
.md-typeset .premium>summary {
  background-color: var(--md-admonition-title-bg);
}

.md-typeset .premium>.admonition-title::before,
.md-typeset .premium>summary::before {
  background-color: var(--md-typeset-muted-color);
  -webkit-mask-image: var(--md-admonition-icon--premium);
  mask-image: var(--md-admonition-icon--premium);
}