.md-content {
  &__inner {

    padding-top: px2rem(36px);

    @include break-from-device(screen) {
      .md-sidebar--primary:not([hidden]) ~ .md-content > & {
        margin-left: px2rem(32px);
      }

      .md-sidebar--secondary:not([hidden]) ~ .md-content > & {
        margin-right: px2rem(32px);
      }
    }

    .glossary-categories {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -20px;
      .md-button {
        margin: 0px px2rem(8px) px2rem(8px) 0px;
      }
    }
  }

  @include break-from-device(tablet landscape) {
    &__inner {
      padding-top: px2rem(12px);
    }
  }
}
