.md-search {
  margin: 0 0.2rem;
  padding: 0 0.4rem;
  .md-search__input {
    border-radius: 0.2rem;
    @include caption;
  }

  .md-search__form {
    height: px2rem(42px);
    &:hover {
      border-radius: 6px;
    }

    .md-search__icon[for=__search] {
      top: px2rem(10px);
    }
  }
  .md-search__input + .md-search__icon {
    color: var(--md-header-button);
  }

  @include break-to-device(tablet portrait) {
    .md-search__input {
      background-color: transparent;
    }
    .md-search__form {
      height: 48px;
    }
  }
}
