body {
  min-width: 300px;
  // prevent page jumping when scroll appears
  margin-right: calc(100vw - 100%);
}

html {
  // prevent page jumping when scroll appears
  margin-right: calc(100% - 100vw);
}


.md-main {
  padding: 0 px2rem(32px);
}

.md-main__inner {
  margin-top:0;
}

.md-grid {
  max-width: 70rem;
}

img {
  max-height: 500px;
}

img[src*="/icon-"] {
  max-width: 20px;
  max-height: 20px;
}

@include break-to-device(mobile) {
  .md-main {
    padding: 0 px2rem(8px);
  }
}

.md-dialog {
  background-color: var(--sidebar-bg);
  left: auto;
  right: 0.8rem;
  &__inner {
    color: var(--md-default-fg-color);
  }
}

.md-dialog[data-md-state="open"] {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
  transition: transform .4s cubic-bezier(.075,.85,.175,1),opacity .4s;
}

.pre-debug {
  color: #000;
  background-color: var(--md-footer-bg-color--dark);
  font-size:12px;
  line-height:14px;
  border: 1px solid #ccc;
  height:300px;
  overflow: scroll;
  &:hover {
    color: #000;
  }
}
