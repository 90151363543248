.md-footer {
  z-index: 1;

  &::after {
    content: '';
    display: block;
    background: var(--md-footer-bg-color) url('../images/DR-pattern-full-width.svg') no-repeat center center;
    background-size: cover;
    width: 100%;
    height: 10px;
  }

  ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .md-footer-meta {
    background-color: transparent;
    margin-right: auto;
    margin-left: auto;


    li {
      margin: 0;
    }

    .md-footer-meta__inner {
      align-items: center;
      margin: 0;
      padding: px2rem(32px) px2rem(16px);
    }

    .actions {
      display: flex;
      align-items: center;
    }
    .caption {
      @include caption;
      color: var(--md-primary-bg-color);
      padding-left: px2rem(24px);
      padding-right: px2rem(12px);
    }

    .action-button {
      display: flex;
      .white-button {
        @include white_link;
        @include caption;
        padding: 0 px2rem(12px);
        margin: 0;
      }
    }

    .selectbox {
      @include dropdown;
      .language-selector {
        visibility: hidden;
      }

      .choices {
        .choices__inner {
          width: 200px;
          color: var(--md-primary-bg-color);
          background-color: var(--language-selector-bg);
          border: none;
          border-radius: 6px;
          padding: px2rem(8px) px2rem(12px);
        }

        .choices__list--dropdown {
          display: none;
        }

        &[data-type*=select-one] {
          &.is-open {
            .choices__inner {
              border: none;
              color: var(--md-primary-bg-color);
            }
            .choices__list--dropdown {
                display: block;
                color: var(--md-default-fg-color);
            }

            .choices__item.choices__item--choice.choices__item--selectable {
              background-repeat: no-repeat;
              background-position: 10% 45%;
            }
          }
          &:after {
            right: 11px;
            background: url("../images/arrow-close-light.png") 0 0 no-repeat transparent;
          }
        }

        .choices__item--selectable {
          background-repeat: no-repeat;
          line-height: initial;
        }

        [data-value*=ja] {
          margin-top: 2px;
          background-image: url('../images/japanese.svg');
        }

        [data-value*=en] {
          margin-top: 2px;
          background-image: url('../images/english.svg');
        }
      }
    }
  }

  @include break-to-device(mobile) {
    .md-footer-meta {
      padding: 0 px2rem(24px);
      .md-footer-meta__inner {
        justify-content: center;
        .actions {
          flex-direction: column;
          .caption {
            padding-bottom: px2rem(24px);
          }
        }
      }
    }

    svg {
      display: none;
    }

    .selectbox {
      display: none;
    }

  }
}
