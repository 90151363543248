.md-typeset .grid.cards {
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));

  h4 {
    margin: px2rem(12px) 0;

    a {
      color: var(--md-typeset-color);
    }
  }

  li {
    cursor: pointer;
    border-radius: 0.5rem;
  }

  .headerlink {
    display: none;
  }
}

.card-grid-page {
  max-width: 50rem;
  margin: 0 auto;
}
