@mixin blue_link {
  color: var(--md-secondary-a-color);
  &:hover {
    color: var(--md-secondary-a-color-hover);
    opacity: 1;
  }
}

@mixin orange_link {
  color: var(--md-primary-a);
  &:hover {
    color: var(--md-primary-a-color-hover);
    opacity: 1;
  }
}

@mixin white_link {
  color: var(--md-primary-bg-color);
  &:hover {
    color: var(--md-secondary-a-color-hover);
    opacity: 1;
  }
}

@mixin grey_link {
  color: var(--md-header-button);
  opacity: 1;
  &:hover {
    color: var(--md-primary-bg-color);
  }
}

@mixin blue_active_button {
  @include button;
  border-radius: 6px;
  border: 1px solid var(--md-secondary-a-color);
  line-height: 16px;
  background: var(--md-secondary-a-color);
  color: var(--md-primary-bg-color);
  &:hover {
    color: var(--md-primary-bg-color);
    background: var(--md-secondary-a-color-hover);
    opacity: 1;
    border: 1px solid var(--md-secondary-a-color-hover);
  }
}

@mixin orange_active_button {
  @include button;
  border-radius: 6px;
  background: var(--md-primary-a);
  color: var(--md-primary-bg-color);
  &:hover {
    background: var(--md-primary-a-color-hover);
  }
}

@mixin button {
  padding: 8px 16px;
  white-space: nowrap;
  cursor: pointer;
}

@mixin filter-button {
  @include button;
  @include action-item-bold;
  font-weight: 600;
  color: var(--md-typeset-color);
  border-radius: 6px;
  border: px2rem(2px) solid var(--md-borders-color);
  padding: px2rem(8px) px2rem(12px);
  &:hover, &:focus {
    border: px2rem(2px) solid var(--md-borders-color);
    background-color: var(--sidebar-bg);
    color: var(--md-typeset-color);
  }
  &.selected {
    border: px2rem(2px) solid var(--md-typeset-a-color);
    background-color: var(--md-typeset-a-color);
    color: var(--md-primary-bg-color);
  }
}

@mixin feedback-button {
  @include button;
  @include action-item;
  font-weight: 700;
  color: var(--md-typeset-a-color);
  padding: 0;
  border: none;
  i {
    display: inline-block;
    fill: var(--md-typeset-a-color);
    width: px2rem(16px);
    height: px2rem(16px);
    margin-right: px2rem(8px);
  }
  &[value="no"] {
    i {
      position: relative;
      top: 3px;
      margin-left: px2rem(26px);
    }
  }
  &:hover, &:focus {
    color: var(--md-secondary-a-color-hover);
    i {
      fill: var(--md-secondary-a-color-hover);
    }
  }
}
