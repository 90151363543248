:root {
  --md-nav-icon--plus: url("data:image/svg+xml;charset=utf-8,<svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M11.5 5C11.6458 5 11.7604 5.05208 11.8438 5.15625C11.9479 5.23958 12 5.35417 12 5.5V6.5C12 6.64583 11.9479 6.77083 11.8438 6.875C11.7604 6.95833 11.6458 7 11.5 7H7V11.5C7 11.6458 6.94792 11.7708 6.84375 11.875C6.76042 11.9583 6.64583 12 6.5 12H5.5C5.35417 12 5.22917 11.9583 5.125 11.875C5.04167 11.7708 5 11.6458 5 11.5V7H0.5C0.354167 7 0.229167 6.95833 0.125 6.875C0.0416667 6.77083 0 6.64583 0 6.5V5.5C0 5.35417 0.0416667 5.23958 0.125 5.15625C0.229167 5.05208 0.354167 5 0.5 5H5V0.5C5 0.354167 5.04167 0.239583 5.125 0.15625C5.22917 0.0520833 5.35417 0 5.5 0H6.5C6.64583 0 6.76042 0.0520833 6.84375 0.15625C6.94792 0.239583 7 0.354167 7 0.5V5H11.5Z' fill='black'/></svg>");
  --md-nav-icon--minus: url("data:image/svg+xml;charset=utf-8,<svg width='12' height='2' viewBox='0 0 12 2' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M11.5 0C11.6458 0 11.7604 0.0520833 11.8438 0.15625C11.9479 0.239583 12 0.354167 12 0.5V1.5C12 1.64583 11.9479 1.77083 11.8438 1.875C11.7604 1.95833 11.6458 2 11.5 2H0.5C0.354167 2 0.229167 1.95833 0.125 1.875C0.0416667 1.77083 0 1.64583 0 1.5V0.5C0 0.354167 0.0416667 0.239583 0.125 0.15625C0.229167 0.0520833 0.354167 0 0.5 0H11.5Z' fill='black'/></svg>");
}
.md-nav {
  &__link {
    &:focus {
      color: var(--md-primary-a-color-focus);
    }
    &:hover {
      color: var(--md-primary-a-color-hover);
    }
    &--active {
      --md-typeset-a-color: var(--md-primary-a);
    }
    color: var(--md-typeset-color);
    @include action-item;
  }

  &__item:not(.md-nav__item--nested) {
    .md-nav__link {
      justify-content: flex-start;
      font-weight: normal;
    }
  }

  &__item--nested {
    .md-nav__link {
      justify-content: flex-start;
      font-weight: bold;
    }
  }

  @include break-from-device(screen) {
    &__icon {
      max-width: px2rem(12px);
      max-height: px2rem(12px);
      padding-top: px2rem(2px);

      // 2024-02-15
      // align plus & minus icons in left nav following material upgrade
      flex-shrink: 0;
      display: inline-flex;
      position: relative;
      margin-top: 5px;
      // end

      &::after {
        vertical-align: top;
        mask-image: var(--md-nav-icon--plus);
        -webkit-mask-image: var(--md-nav-icon--plus);
      }
      &:hover {
        background-color: transparent;
      }
    }
    &__item--nested {
      .md-nav__toggle:checked,
      .md-nav__toggle:indeterminate {
        ~.md-nav__link .md-nav__icon {
            transform: rotate(90deg);
            padding-top: px2rem(5px);
            &::after {
              mask-image: var(--md-nav-icon--minus);
              -webkit-mask-image: var(--md-nav-icon--minus);
            }
        }
      }
    }
  }

  .preview-alert, .platform-alert, .pricing-alert {
    font-weight: bold;
    padding: 12px 8px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 6px;
    background: var(--md-admonition-title-bg);

    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;

    span {
      display: inline-flex;
    }

    .preview-icon, .platform-icon, .pricing-icon {
      fill: var(--md-typeset-muted-color);
      width: 20px;
      margin-right: 5px;
    }
  }


  @include break-to-device(tablet) {

    .md-nav__item {
      &--active > .md-nav__link > .md-nav__link {
        color: var(--md-primary-a);
      }
    }

    .md-nav__link {
      .md-nav__icon {
        position: absolute;
        right: px2rem(12px);
      }
    }
  }
}

[dir=ltr] .md-nav--primary .md-nav__list {
  padding-left: 0px;
}

// 2024-02-15 - Material Insiders
// Adjust subtitle text and spacing in /common/_themes/overrides/partials/nav-item.html
.md-nav__link {
  .md-ellipsis {
    small {
      opacity: .8;
      line-height: 20px;
      display: block;
    }
  }
}
// end
