.md-top {
  background: var(--md-primary-bg-color);
  color: var(--md-typeset-color);

  // Back-to-top button on focus/hover
  &:focus,
  &:hover {
    background: var(--md-selectbox-color);
  }
  @include break-to-device(tablet) {
    margin-top: px2rem(40px);
  }
}
