.md-tabs {
  background-color: var(--md-default-fg-color);
  height: 50px;
  z-index: 1;
  transition: top 0.2s;
  overflow: hidden;

  .md-tabs__list {
    display: flex;
    align-items: baseline;
  }

  .md-tabs__inner {
    margin-top: px2rem(5px);
  }

  .md-tabs__link {
    @include action-item;
    @include white_link;
    opacity: 1;
    margin-top: 0;
  }

  .md-tabs__link--active {
    @include blue_active_button;
  }

}

.mobile-notification {
  display: block;
  z-index: 1;
  width: 100%;
  color: var(--md-secondary-a-color);
  background-color: var(--sidebar-bg);
  padding: px2rem(8px) 0;
  border-bottom: 1px solid var(--md-typeset-a-color);
  text-align: center;
  @include regular;

  i {
    display: inline-block;
    width: 13px;
    height: 13px;
    fill: var(--md-secondary-a-color);
    background-repeat: no-repeat;
    background-image: url("../images/question.svg");
  }
  p {
    margin: 0 px2rem(24px);
  }
}

@include break-from-device(tablet landscape) {
  .mobile-notification {
    display: none;
  }
}

@include break-to-device(mobile) {
  .mobile-notification {
    text-align: left;
  }
}
