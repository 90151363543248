@mixin dropdown {
  .choices {
    max-width: 220px;
    margin-bottom: px2rem(12px);
    .choices__inner {
      @include select-box;
      background: none;
      border: 1px solid var(--md-selectbox-color);
      border-radius: 6px;
      padding: 8px 16px;
      color: var(--md-selectbox-color);
      min-height: px2rem(40px);
      height: px2rem(40px);
      .choices__list--single {
        padding: 0 16px 0 0;
        .choices__item--selectable {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
    &[data-type*=select-one] {
      &.is-open {
        .choices__inner {
          border: 2px solid var(--md-secondary-a-color);
          color: var(--md-typeset-color);
        }
        .choices__list--dropdown {
          width: 99%;
          border-radius: 6px;
          box-shadow: 0px 6px 8px rgba(54, 77, 114, 0.08), 0px 2px 4px rgba(54, 77, 114, 0.18);
          border-color: transparent;
          margin-top: 1px;
          border: none;
          word-break: break-word;
          .choices__item--selectable {
            @include select-box;
            padding: 11px 16px;
            &.is-highlighted {
              background-color: var(--md-selectbox-background);
            }
          }
          .choices__list {
            max-height: none;
          }
        }
        &:after {
          background: url("../images/arrow-open-blue.png") 0 0 no-repeat transparent;
        }
      }
      &:after {
        width: 10px;
        height: 6px;
        border: none;
        background: url("../images/arrow-close-dark.png") 0 0 no-repeat transparent;
        right: 17px;
        margin-top: -3px;
      }
    }
  }
}
