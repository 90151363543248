:root {
  --md-primary-fg-color:              #FF5600;
  --md-default-fg-color:              #0A0A0C;
  --sidebar-bg:                       #F2F6F9;
  --md-primary-bg-color:              #FFFFFF;

  --md-header-button:                 #BCBCBE;

  --md-accent-fg-color:               #2CAEFF;

  --md-selectbox-color:               #53718F;

  --md-selectbox-background:          #F1F5F8;

  //admonitions
  --admonition-tip: #00bfa5;
  --admonition-tip-rgb: rgba(0, 191, 165, 0.1);

  --admonition-note: #448aff;
  --admonition-note-rgb: rgba(68, 138, 255, 0.1);

  --admonition-info: #53718F;
  --admonition-info-rgb: rgba(83, 113, 143, 0.1);

  --admonition-warning: #ff9100;
  --admonition-warning-rgb: rgba(255, 145, 0, 0.1);

  --admonition-faq: #1372C3;
  --admonition-faq-rgb: rgba(19, 114, 195, 0.1);

  > * {
    --md-typeset-color:               #23232D;
    --md-typeset-a-color:             #2D8FE2;
    --md-typeset-muted-color:         #53718F;
    --md-primary-a:                   #FF5600;
    --md-primary-a-color-hover:       #FF8200;
    --md-primary-a-color-focus:       #E64D00;

    --md-secondary-a-color:           #2D8FE2;
    --md-secondary-a-color-hover:     #2CAEFF;
    --md-secondary-a-color-focus:     #1372C3;

    --md-tags-color:                  #E1E8F0;
    --md-borders-color:               #D0D8E2;

    --md-footer-bg-color:             #0A0A0C;

    --input-placeholder:             #c4c8c9;
    --input-box-shadow:              #303335;
    --input-bg-hovered:              #53585B;
    --input-hovered-border-color:    #81868A;
    --md-text-gray-out:              #9facbc;

    --md-admonition-title-bg:         rgba(83, 113, 143, .1);
    --language-selector-bg:           rgba(148, 150, 175, 0.16);
    --main-button-hover-bg:           rgba(45, 143, 226, 0.08);
  }
}
