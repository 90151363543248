.md-header {
  background-color: var(--md-default-fg-color);
  z-index: 2;
  padding: 0 px2rem(32px);

  ul {
    list-style: none;
  }

  .md-header__inner {
    display: flex;
    justify-content: space-between;
    align-items: initial;
    padding: 0;
    height: 110px;
  }

  .md-header__button:hover {
    opacity: 1;
  }

  &__button {
    &.md-logo {
      padding: px2rem(16px) px2rem(8px);
      margin: px2rem(12px) px2rem(8px);
      img, svg {
        min-width: 130px;
        min-height: 62px;
        width: 133px;
        height: 20px;
      }
      &.release {
        img, svg {
          min-width: 130px;
          min-height: auto;
          width: 133px;
          height: auto;
        }
      }
    }

    &.private-logo {
      padding: px2rem(8px);
    }
  }

  .main-navigation {
    .first-level-navigation {
      display: flex;
      justify-content: flex-end;

      .md-header__button {
        vertical-align: initial;
        padding: px2rem(12px) px2rem(8px) px2rem(4px);
        margin: px2rem(8px) px2rem(4px);
        @include caption;
        @include white_link;
        &:hover {
          color: var(--md-header-button);
        }
      }
      .attention {
        @include orange_link;
      }
    }
    .second-level-container {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin: px2rem(4px);

      .second-level-navigation {
        display: flex;
        margin: 0;

        .md-header__button {
          vertical-align: initial;
          padding: 0 px2rem(8px);
          margin: px2rem(4px);
          @include action-item;
          @include white_link;
          font-weight: 600;

        }

        .md-header__button:lang(ja)  {
          font-size: 14px;
        }

        .link-active {
          color: var(--md-secondary-a-color);
          font-weight: 600;
        }
      }

      .md-search {
        display: flex;
        margin: px2rem(0px) px2rem(8px) px2rem(4px) px2rem(4px);
        padding: 0 px2rem(8px);

        .md-search__inner {
          background-color: var(--md-typeset-color);
          border-radius: 6px;
          padding: 0;
        }
      }
    }

    .md-tabs-list {
      margin-bottom: px2rem(16px);
      .md-tabs__link {
        @include action-item;
        @include blue_link;
        opacity: 1;
      }
      .md-tabs__link--active {
        @include blue_active_button;
      }
    }
  }

  .private-main-navigation {
    display: flex;
    align-items: center;
  }

  .md-header__title {
    display: flex;
    justify-content: flex-end;
    margin: 0 px2rem(8px);
    height: initial;
    line-height: initial;
  }

  .expanded {
    .first-level-navigation {
      display: inherit;
      flex-direction: row-reverse;
    }
  }

  @include break-from-device(screen) {
    .md-search__inner {
      max-width: 570px;
    }

    .md-search__scrollwrap {
      max-width: 570px;
    }

    &__inner {
      [data-md-toggle="search"]:checked ~ .md-header & {
        .second-level-navigation {
          display: none;
        }
      }

    }
  }

  @include break-to-device(tablet) {
    padding: 0;
    .md-header__button.md-logo {
      display: initial;
      padding: px2rem(8px);
    }

    .main-navigation {
      display: flex;
      align-items: center;

      .first-level-navigation {
        display: none;
      }
      .second-level-container {
        .second-level-navigation {
          display: none;
        }
      }
    }

    .drawer {
      margin: 0;
      padding:0;
      display: initial;
      position: absolute;
      right: 48px;
      top: 34px;

      svg {
        width: 30px;
        height: 30px;
      }
    }

    .mkdocs-search {
      padding-right: px2rem(64px);
    }
  }

  @include break-to-device(mobile) {
    padding: 0;
    .drawer {
      right: 24px;
      top: 34px;
    }
  }
}
