:root {
  --md-text-font-family: 'Inter', sans-serif;
  --md-text-font-family-secondary: var(--md-text-font-family);
  --font-family-monospace: 'Roboto Mono', sans-serif;
}

@mixin h1 {
  font-family: var(--md-text-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.4px;
}

@mixin h2 {
  font-family: var(--md-text-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.4px;
}

@mixin h3 {
  font-family: var(--md-text-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.4px;
}

@mixin h4 {
  font-family: var(--md-text-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.4px;
}

@mixin subtitle {
  font-family: var(--md-text-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 38px;
}

@mixin subtitle-bold {
  font-family: var(--md-text-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

@mixin paragraph-regular {
  font-family: var(--md-text-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
}

@mixin paragraph-bold {
  font-family: var(--md-text-font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
}

@mixin caption {
  font-family: var(--md-text-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

@mixin select-box {
  font-family: var(--md-text-font-family);
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
}

// Menu item - Links/Buttons
@mixin action-item {
  font-family: var(--md-text-font-family);
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
}

@mixin action-item-bold {
  font-family: var(--md-text-font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

@mixin regular {
  font-family: var(--md-text-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}
