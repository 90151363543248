.md-typeset {
  font-size: px2rem(16px);
  line-height: px2rem(26px);

  :target {
    scroll-margin-top: 8em;
  }

  h1 {
    word-wrap: break-word;
    color: var(--md-typeset-color);
    margin-bottom: px2rem(16px);
    @include h2;
  }

  h2 {
    word-wrap: break-word;
    color: var(--md-typeset-color);
    margin-bottom: px2rem(16px);
    @include h3;
  }

  h3 {
    word-wrap: break-word;
    color: var(--md-typeset-color);
    margin-bottom: px2rem(16px);
    @include h4;
  }

  h4 {
    word-wrap: break-word;
    color: var(--md-typeset-muted-color);
    @include paragraph-bold;
    font-size: 20px;
    line-height: 24px;
  }

  ul,
  ol,
  dl,
  p {
    margin: 0 0 px2rem(16px) 0;
  }

  .admonition {
    border-radius: px2rem(6px);
    @include caption;
    p:last-child {
      margin: px2rem(8px) 0;
    }
    :is(.admonition-title, summary) {
      border-radius: px2rem(6px);
      border-color: transparent;
    }
  }
  details.faq {
    border-color: var(--admonition-faq);
    summary {
      background-color: var(--admonition-faq-rgb);
      border-color: var(--admonition-faq);
      &::before {
        background-color: var(--admonition-faq);
      }
    }
  }

  details {
    @include caption;

    summary + p{
      margin-top: px2rem(12px);
    }
  }

  &__table {
    table {
      border: 0;
      border-radius: 6px;
      border-collapse: collapse;
      box-shadow: 0 6px 8px rgba(54, 77, 114, 0.08),
        0 2px 4px rgba(54, 77, 114, 0.18);

      th {
        background-color: var(--md-tags-color);
        color: var(--md-default-fg-color);
        @include caption;
        font-weight: bold;
        text-transform: uppercase;
      }

      th,
      td {
        border: 1px solid var(--md-borders-color);
      }

      td {
        code.scrollable {
          display: inline-block;
          vertical-align: bottom;
          overflow-x: auto;
          white-space: nowrap;
        }

        ::-webkit-scrollbar {
          -webkit-appearance: none;
          height: 5px;
        }

        ::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }
      }
    }
  }

  table:not([class]) {
    border: 0;
    border-radius: 6px;
    border-collapse: collapse;
    box-shadow: 0 6px 8px rgba(54, 77, 114, 0.08),
      0 2px 4px rgba(54, 77, 114, 0.18);
    font-size: px2rem(14px);

    th {
      background-color: var(--md-tags-color);
      color: var(--md-default-fg-color);
      @include caption;
      font-weight: bold;
      text-transform: uppercase;
    }

    th,
    td {
      border: 1px solid var(--md-borders-color);
      padding: px2em(12px) px2em(16px);
    }
    td {
      ul {
        margin-top: 10px;
      }
    }
  }

  img,
  svg,
  code {
    border-radius: 6px;
  }

  .md-source-date {
    small {
      opacity: 1;
    }
    .grey-text {
      color: var(--md-typeset-color);
      opacity: 0.5;
    }
  }

  .tabbed-set {
    border-top: 1px solid var(--md-default-fg-color--lightest);
    border-bottom: 1px solid var(--md-default-fg-color--lightest);
  }

  .tabbed-content {
    padding: px2em(12px) 0;
  }

  h1 .headerlink {
    display: none;
  }

  @mixin backgroundSVG($color: "rgb(45 143 226)") {
    background-image: url('data:image/svg+xml;utf8,<svg fill="#{$color}" width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1520 1216q0-40-28-68l-208-208q-28-28-68-28-42 0-72 32 3 3 19 18.5t21.5 21.5 15 19 13 25.5 3.5 27.5q0 40-28 68t-68 28q-15 0-27.5-3.5t-25.5-13-19-15-21.5-21.5-18.5-19q-33 31-33 73 0 40 28 68l206 207q27 27 68 27 40 0 68-26l147-146q28-28 28-67zm-703-705q0-40-28-68l-206-207q-28-28-68-28-39 0-68 27l-147 146q-28 28-28 67 0 40 28 68l208 208q27 27 68 27 42 0 72-31-3-3-19-18.5t-21.5-21.5-15-19-13-25.5-3.5-27.5q0-40 28-68t68-28q15 0 27.5 3.5t25.5 13 19 15 21.5 21.5 18.5 19q33-31 33-73zm895 705q0 120-85 203l-147 146q-83 83-203 83-121 0-204-85l-206-207q-83-83-83-203 0-123 88-209l-88-88q-86 88-208 88-120 0-204-84l-208-208q-84-84-84-204t85-203l147-146q83-83 203-83 121 0 204 85l206 207q83 83 83 203 0 123-88 209l88 88q86-88 208-88 120 0 204 84l208 208q84 84 84 204z"/></svg>');
  }

  .headerlink {
    display: inline-block;
    font-size: 0;
    &::after {
      content: " ";
      display: inline-block;
      width: 16px;
      height: 16px;
      background-size: 16px 16px;
      @include backgroundSVG("rgba(0,0,0,0.32)");
    }
    &:hover {
      &::after {
        @include backgroundSVG();
      }
    }
  }

  :target .headerlink {
    &::after {
      @include backgroundSVG();
    }
  }

  .md-button {
    @include filter-button;
  }

  .output_area {
    .output_html {
      padding: px2em(12px) 0;
      table {
        width: 100%;
        overflow: auto;
        display: block;

        scrollbar-width: thin;
        scrollbar-color: var(--md-default-fg-color--lighter) transparent;

        // Code block on hover
        &:hover {
          scrollbar-color: var(--md-accent-fg-color) transparent;
        }

        // Webkit scrollbar
        &::-webkit-scrollbar {
          width: px2rem(4px);
          height: px2rem(4px);
        }

        // Webkit scrollbar thumb
        &::-webkit-scrollbar-thumb {
          background-color: var(--md-default-fg-color--lighter);

          // Webkit scrollbar thumb on hover
          &:hover {
            background-color: var(--md-accent-fg-color);
          }
        }
      }
    }
  }


  @include break-to-device(tablet) {
    :target {
      scroll-margin-top: 6em;
    }
  }

  @include break-to-device(mobile) {
    :target {
      scroll-margin-top: 8.5em;
    }
  }
}
