.algolia-search {
  display: flex;

  .search-input {
    .ais-SearchBox-form {
      display: flex;
      position: relative;

      input {
        width: 234px;
        padding: px2rem(8px) 0 px2rem(8px) px2rem(32px);
        font-size: 14px;
        line-height: 20px;
        color: var(--md-primary-bg-color);
        background-color: var(--md-typeset-color);
        border: none;
        border-radius: 6px;
        transition: background-color 0.2s linear, box-shadow 0.2s linear;
        box-shadow: inset 0 0 0 1px var(--input-box-shadow);
        &::placeholder {
          color: var(--input-placeholder);
        }
        &:hover {
          background-color: var(--input-bg-hovered);
          box-shadow: inset 0 0 0 1px var(--input-hovered-border-color);
        }

        &:focus {
          background-color: var(--md-primary-bg-color);
          color: var(--md-typeset-color);
        }

        &::-webkit-search-cancel-button {
          -webkit-appearance: none;
        }

        &::-webkit-search-decoration {
          display: none;
        }
      }

      .ais-SearchBox-reset {
        right: 40px;
        position: absolute;

        svg {
          height: 14px;
          width: 14px;
        }
      }

      .ais-SearchBox-loadingIndicator {
        display: none;
      }

      svg {
        left: 10px;
        position: absolute;
        height: 17px;
        width: 17px;
        fill: var(--md-header-button);
      }
    }
  }

  @include break-to-device(tablet) {
    padding-right: px2rem(32px);
  }

  @include break-to-device(mobile) {
    padding-right: px2rem(16px);

    .search-input {
      .ais-SearchBox-form {
        input {
          width: 100%;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
