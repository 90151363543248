.md-nav--secondary {
  .notification {
    color: var(--md-secondary-a-color);
    margin-left: px2rem(6px);
    margin-bottom: px2rem(32px);
    @include regular;
    i {
      display: inline-block;
      width: 13px;
      height: 13px;
      fill: var(--md-secondary-a-color);
      background-repeat: no-repeat;
      background-image: url("../images/question.svg");
    }
    p {
      margin: 0;
    }
  }

  .nav-wrapper {
    .md-nav__title {
      padding-bottom: 6px;
      box-shadow: none;
      color: black;
    }
    .md-nav__item {
      line-height: 26px;
      a {
        display: inline-block;
        line-height: 18px;
        width: 100%;
        word-wrap: break-word;
      }
    }
    .indent-toc-content {
      margin-left: px2rem(12px);
    }
  }

  @include break-to-device(mobile) {
    .notification {
      display: none;
    }
    .feedback {
      display: none;
    }
  }
}

.md-nav--secondary .md-nav__list {
    padding-bottom: px2rem(12px);
}

[dir=ltr] .md-nav--secondary .md-nav__list {
    padding-left: 0px;
}
